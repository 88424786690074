<template>
  <div>
    <a-table
      style="margin-bottom: 16px;"
      class="custom-table"
      :columns="columns"
      :data-source="data"
      :pagination="{pageSize: 10, total, showTotal: total => `共 ${total} 条`}"
      row-key="id"
      :scroll="{ x: scrollX }"
      :loading="loading"
      :components="components"
    >
    <template slot="name" slot-scope="text, record">
      <a-tooltip placement="topLeft" :title="text">
        <a @click="$router.push({name: 'ServiceDashboardDetail', params: {id: record.id}})">
          {{ text }}
        </a>
      </a-tooltip>
    </template>
    <template slot="description" slot-scope="record">
      <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
    </template>
    <template slot="status" slot-scope="text">
      <source-status-tag :status="text"></source-status-tag>
    </template>
    <template slot="important" slot-scope="record">
      <a-tag color="red" v-if="record">重点</a-tag>
      <a-tag color="blue" v-else>一般</a-tag>
    </template>
    <template slot="domains" slot-scope="record">
      <span v-if="!record.length">-</span>
      <template v-else>
        <a-tooltip placement="topLeft" :title="record[0].name">{{record[0].name}}</a-tooltip>
      </template>
    </template>
    <template slot="sslCertificates" slot-scope="record">
      <span v-if="!record.length">-</span>
      <template v-else>
        <a-tooltip placement="topLeft" :title="record[0].name">{{record[0].name}}</a-tooltip>
      </template>
    </template>
    <template slot="operation" slot-scope="text, record">
      <edit-button v-permission="{action: 'service.service.update', effect: 'disabled'}" @click="$refs.inputDrawer.show(record.id)"></edit-button>
      <delete-button v-permission="{action: 'service.service.delete', effect: 'disabled'}" @confirm="handleDelete(record.id)"></delete-button>
    </template>
    </a-table>
    <input-drawer ref="inputDrawer" @ok="$emit('ok')"></input-drawer>
  </div>
</template>

<script>
import {
  deleteService
} from '@/api/service'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import InputDrawer from './InputDrawer.vue'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'ServiceTable',
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number
    },
    statusList: {
      type: Array
    }
  },
  components: { EditButton, DeleteButton, InputDrawer, SourceStatusTag },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'important',
          title: '级别',
          width: 90,
          scopedSlots: {
            customRender: 'important'
          }
        },
        {
          dataIndex: 'project_manager',
          title: '项目经理',
          width: 100,
          scopedSlots: {
            customRender: 'projectManager'
          }
        },
        {
          dataIndex: 'construction_manager',
          title: '承建经理',
          width: 100,
          scopedSlots: {
            customRender: 'constructionManager'
          }
        },
        {
          dataIndex: 'domains',
          title: '域名',
          ellipsis: true,
          width: 140,
          scopedSlots: {
            customRender: 'domains'
          }
        },
        {
          dataIndex: 'ssl_certificates',
          title: 'SSL 证书',
          ellipsis: true,
          width: 140,
          scopedSlots: {
            customRender: 'sslCertificates'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 190,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      tableData: [],
      loading: false,
      data: [],
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  methods: {
    handleDelete (id) {
      deleteService(id).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
      })
    }
  },
  mounted () {
    this.loading = true
    if (this.statusList.length === this.dataSource.length) {
      this.tableData = []
      this.dataSource.forEach(item => {
        const obj = { ...item }
        const statusObj = this.statusList.find(e => e.id === item.id)
        if (statusObj) obj.status = statusObj.status
        else obj.status = 'unknown'
        this.tableData.push(obj)
      })
      this.data = this.tableData
      this.loading = false
    }
  },
  watch: {
    statusList: {
      handler (v) {
        this.loading = true
        if (v.length === this.dataSource.length) {
          this.tableData = []
          this.dataSource.forEach(item => {
            const obj = { ...item }
            const statusObj = v.find(e => e.id === item.id)
            if (statusObj) obj.status = statusObj.status
            else obj.status = 'unknown'
            this.tableData.push(obj)
          })
          if ((this.total && this.tableData.length) || this.total === 0) {
            this.data = this.tableData
            this.loading = false
          }
        }
      },
      deep: true
    },
    total (v) {
      if (v === 0) {
        this.data = []
        this.loading = false
      }
    }
  }
}
</script>
