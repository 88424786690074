<template>
  <div class="container"
    @click="$router.push({
      name: 'ServiceDashboardDetail',
      params: {
        id: service.id
      }
    })"
  >
    <div class="header">
      <a
        class="title"
        @click="$router.push({
          name: 'ServiceDashboardDetail',
          params: {
            id: service.id
          }
        })"
      >
        {{ service.name }}
      </a>
      <span class="more-btn">
        <a-dropdown>
          <a-icon type="ellipsis" style="cursor: pointer" />
          <a-menu slot="overlay">
            <a-menu-item>
              <edit-button v-permission="{action: 'service.service.update', effect: 'disabled'}" @click="$refs.inputDrawer.show(service.id)"></edit-button>
            </a-menu-item>
            <a-menu-item>
              <delete-button v-permission="{action: 'service.service.delete', effect: 'disabled'}" @confirm="confirm"></delete-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </div>

    <div :class="messageClass">
      <div style="height: 36px; margin-right: 32px; font-size: 36px">
        <message-icon :icon="messageIcon" style="vertical-align: 4px"></message-icon>
      </div>
      <div
        style="
          line-height: 20px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
        "
      >
        <!-- 应用异常 -->
        <template v-if="abnormalApplicationCount">
          有
          <span style="color: #ff4d4f; font-size: 16px">
            {{ abnormalApplicationCount }}
          </span>
          个应用有异常
        </template>

        <!-- 严重告警 -->
        <template v-else-if="serviceAlert.disaster + serviceAlert.high">
          <div>应用无异常，</div>
          <div>
            有
            <span style="color: #faad14; font-size: 16px">
              {{ serviceAlert.disaster + serviceAlert.high }}
            </span>
            条 严重、紧急级别的告警
          </div>
        </template>

        <!-- 计划告警 -->
        <template v-else-if="serviceAlert.average + serviceAlert.warning">
          <div>应用无异常，</div>
          <div>
            有
            <span style="color: #096dd9; font-size: 16px">
              {{ serviceAlert.average + serviceAlert.warning }}
            </span>
            条 普通、预警级别的告警
          </div>
        </template>

        <!-- 健康 -->
        <template v-else>无告警，应用无异常</template>
      </div>
    </div>

    <div class="split" style="margin-bottom: 16px"></div>

    <a-row style="text-align: center; margin-bottom: 16px">
      <a-col :span="8">
        <a-statistic
          :value="statistic['95thRequestTime']"
          :value-style="valueStyle"
        >
          <question-icon slot="title" title="P95" description="95% 请求的响应时间(ms)"></question-icon>
        </a-statistic>
      </a-col>
      <a-col :span="8">
        <a-statistic
          title="请求数"
          :value="statistic.requests"
          :value-style="valueStyle"
        >
        </a-statistic>
      </a-col>
      <a-col :span="8">
        <a-statistic
          title="错误数"
          :value="statistic.requestsFailed"
          :value-style="valueStyle"
        >
        </a-statistic>
      </a-col>
    </a-row>

    <a-space style="margin-bottom: 8px">
      <span>请求成功率</span>
      <a-tooltip>
        <a-icon type="question-circle"></a-icon>
        <template slot="title">
          <div>
            <a-badge :color="color.green"></a-badge>
            <span>请求成功率大于等于 90%</span>
          </div>
          <div>
            <a-badge :color="color.orange"></a-badge>
            <span>请求成功率大于等于 60% </span>
          </div>
          <div>
            <a-badge :color="color.red"></a-badge>
            <span>请求成功率小于 60% </span>
          </div>
        </template>
      </a-tooltip>
    </a-space>
    <success-rate-chart
      :chart-data="chartData"
      :chart-id="`${service.name}-chart`"
      :height="42"
    ></success-rate-chart>

    <input-drawer ref="inputDrawer" @ok="$emit('ok')"></input-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import {
  deleteService,
  getServiceAlertCount,
  getServiceApplicationList,
  getServiceStatistic,
  getServiceTrend
} from '@/api/service'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import QuestionIcon from '@/components/icon/QuestionIcon'
import { greenColor, orangeColor, redColor } from '@/utils/const'
import InputDrawer from './InputDrawer'
import MessageIcon from './MessageIcon'
import SuccessRateChart from './SuccessRateChart'

export default {
  name: 'ServiceCard',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer,
    MessageIcon,
    QuestionIcon,
    SuccessRateChart
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valueStyle: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '18px',
        fontWeight: 500
      },
      color: {
        green: greenColor,
        orange: orangeColor,
        red: redColor
      },
      abnormalApplicationCount: 0,
      serviceAlert: {
        disaster: 0,
        high: 0,
        average: 0,
        warning: 0
      },
      messageIcon: 'healthy',
      messageClass: 'message-container message-container-healthy',
      datetimeRange: [moment().subtract(30, 'minute'), moment()],
      chartData: [],
      statistic: {
        '95thRequestTime': 0,
        requests: 0,
        requestsFailed: 0
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    reset () {
      this.serviceAlert = {
        disaster: 0,
        high: 0,
        average: 0,
        warning: 0
      }
    },
    fetch () {
      const p1 = getServiceApplicationList(this.service.id).then(res => {
        const data = res.data
        data.data.forEach(application => {
          if (application.status !== 'normal') this.abnormalApplicationCount++
        })
      })
      const p2 = getServiceStatistic(this.service.id).then(res => {
        const data = res.data
        this.statistic['95thRequestTime'] = data['95th_request_time']
        this.statistic.requests = data.requests
        this.statistic.requestsFailed = data.requests_failed
      })
      const p3 = getServiceTrend(this.service.id, {
        key: 'request_success_rate',
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        this.chartData = res.data.data
      })
      const p4 = getServiceAlertCount(this.service.id, {
        count_of: 'severity',
        recovered: false
      }).then(res => {
        const data = res.data.data
        if (data.length) {
          this.reset()
          data.forEach(alert => {
            this.serviceAlert[alert.name] += alert.value
          })
          this.$emit('alert', {
            alert: true,
            id: this.service.id
          })
        } else {
          this.$emit('alert', {
            alert: false,
            id: this.service.id
          })
        }
      })
      Promise.all([p1, p2, p3, p4]).then(() => {
        if (this.abnormalApplicationCount) this.$emit('getStatus', { status: 'abnormal', service: this.service })
        else {
          let alert = 0
          for (const key in this.serviceAlert) {
            if (Object.hasOwnProperty.call(this.serviceAlert, key)) {
              const v = this.serviceAlert[key]
              alert += v
            }
          }
          if (alert) this.$emit('getStatus', { status: 'alert', service: this.service })
          else this.$emit('getStatus', { status: 'normal', service: this.service })
        }
      })
    },
    confirm () {
      deleteService(this.service.id).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
      })
    }
  },
  watch: {
    service () {
      this.fetch()
    },
    serviceAlert: {
      handler (v) {
        if (this.abnormalApplicationCount) {
          this.messageIcon = 'urgent'
          this.messageClass = 'message-container message-container-urgent'
        } else {
          if (v.disaster + v.high) {
            this.messageIcon = 'important'
            this.messageClass = 'message-container message-container-important'
          } else if (v.average + v.warning) {
            this.messageIcon = 'attention'
            this.messageClass = 'message-container message-container-attention'
          } else {
            this.messageIcon = 'healthy'
            this.messageClass = 'message-container message-container-healthy'
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.container {
  height: 330px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  cursor: pointer;
}
.header {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 48px;
  box-sizing: border-box;
}
.more-btn {
  float: right;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.25);
}

.message-container {
  height: 72px;
  margin: 14px 5px 20px 5px;
  position: relative;
  padding: 12px 18px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.message-container::after {
  content: '';
  width: 100%;
  height: 72px;
  border-radius: 8px;
  opacity: 0.09;
  position: absolute;
  top: 0;
  left: 0;
}
.message-container-urgent {
  color: #ff4d4f;
}
.message-container-urgent::after {
  background: #ff4d4f;
}
.message-container-important {
  color: #faad14;
}
.message-container-important::after {
  background: #faad14;
}
.message-container-attention {
  color: #096dd9;
}
.message-container-attention::after {
  background: #096dd9;
}
.message-container-healthy {
  color: #52c41a;
}
.message-container-healthy::after {
  background: #52c41a;
}

.split {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
</style>
