<template>
  <div class="service-wall">
    <a-tabs v-model="activeTab">
      <a-tab-pane key="overview" tab="总览">
        <div>
          <a-row :gutter="16">
            <a-col
              :sm="24"
              :md="12"
              :lg="6"
              v-for="(item, index) in serviceCount"
              :key="index"
              style="margin-bottom: 16px"
            >
              <service-count-card
                :title="item.title"
                :count="item.count"
                :icon="item.icon"
              ></service-count-card>
            </a-col>
          </a-row>
          <a-spin :spinning="spinning">
            <a-collapse :bordered="false" v-model="activeKey">
              <a-collapse-panel
                key="important"
                :showArrow="false"
                :style="customStyle"
              >
                <template slot="header">
                  <div class="collapse-title">
                    <span>重点业务</span>
                    <a-icon
                      class="collapse-icon"
                      :type="collapseIcon('important')"
                      theme="filled"
                    ></a-icon>
                  </div>
                </template>
                <a-row v-if="service.important.length" :gutter="16">
                  <a-col
                    v-for="service in service.important"
                    :key="service.id"
                    :md="12"
                    :lg="8"
                    :xxl="6"
                    style="margin-bottom: 16px"
                  >
                    <service-card
                      :service="service"
                      @ok="refresh"
                      @alert="alert"
                      @getStatus="getStatus"
                    ></service-card>
                  </a-col>
                </a-row>
                <div v-else style="height: 228px; position: relative; overflow: hidden;">
                  <empty-component
                    :imgUrl="require('@/assets/images/info_empty.png')"
                    :body-style="{
                      height: '216px',
                      marginTop: '0px'
                    }"
                    :description-style="{
                      marginTop: '-32px',
                      marginRight: '8px'
                    }"
                    description="暂无重点业务"
                  ></empty-component>
                </div>
              </a-collapse-panel>
              <a-collapse-panel
                key="normal"
                :showArrow="false"
                :style="customStyle"
              >
                <template #header>
                  <div class="collapse-title">
                    <span>一般业务</span>
                    <a-icon
                      class="collapse-icon"
                      :type="collapseIcon('normal')"
                      theme="filled"
                    ></a-icon>
                  </div>
                </template>
                <a-row v-if="service.normal.length" :gutter="16">
                  <a-col
                    v-for="service in service.normal"
                    :key="service.id"
                    :md="12"
                    :lg="8"
                    :xxl="6"
                    style="margin-bottom: 16px"
                  >
                    <service-card
                      :service="service"
                      @ok="refresh"
                      @alert="alert"
                      @getStatus="getStatus"
                    ></service-card>
                  </a-col>
                </a-row>
                <div v-else style="height: 228px; position: relative; overflow: hidden;">
                  <empty-component
                    :imgUrl="require('@/assets/images/info_empty.png')"
                    :body-style="{
                      height: '216px',
                      marginTop: '0px'
                    }"
                    :description-style="{
                      marginTop: '-32px',
                      marginRight: '8px'
                    }"
                    description="暂无一般业务"
                  ></empty-component>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </a-spin>
        </div>
      </a-tab-pane>
      <a-tab-pane key="list" tab="列表详情">
        <div
          style="
            background: #fff;
            border-radius: 8px;
            padding: 16px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
          "
        >
          <div style="margin-bottom: 16px">
            <create-button
              text="创建新业务"
              v-permission="{action: 'service.service.create', effect: 'disabled'}"
              @click="$refs.inputDrawer.show()"
            ></create-button>
            <a-input-search
              class="service-search"
              style="float: right"
              placeholder="请输入业务名称"
              @search="search"
            ></a-input-search>
          </div>
          <service-table :data-source="dataSource" :total="total" @ok="refresh" :statusList="statusList"></service-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <input-drawer ref="inputDrawer" @ok="refresh"></input-drawer>
  </div>
</template>

<script>
import { getServiceList } from '@/api/service'
import InputDrawer from './modules/InputDrawer.vue'
import ServiceCard from './modules/ServiceCard'
import ServiceCountCard from './modules/ServiceCountCard'
import ServiceTable from './modules/ServiceTable.vue'
import CreateButton from '@/components/button/CreateButton'

export default {
  name: 'ServiceDashboardList',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    InputDrawer,
    ServiceCard,
    ServiceCountCard,
    ServiceTable,
    CreateButton
  },
  data () {
    return {
      activeTab: 'overview',
      form: {
        name: ''
      },
      spinning: false,
      service: {
        important: [],
        normal: []
      },
      dataSource: [],
      total: 0,
      alertService: [],
      activeKey: ['important', 'normal'],
      customStyle: {
        background: '#ffffff',
        borderRadius: '8px',
        marginBottom: '16px',
        border: 0
      },
      params: {
        page: 1,
        page_size: 10
      },
      statusList: []
    }
  },
  computed: {
    serviceCount () {
      return [
        {
          title: '全部业务数量',
          count: this.total,
          icon: 'all'
        },
        {
          title: '重点业务数量',
          count: this.service.important.length,
          icon: 'important'
        },
        {
          title: '一般业务数量',
          count: this.service.normal.length,
          icon: 'normal'
        },
        {
          title: '告警业务数量',
          count: this.alertService.length,
          icon: 'alert'
        }
      ]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    collapseIcon (key) {
      return this.activeKey.findIndex(item => item === key) !== -1
        ? 'up-circle'
        : 'down-circle'
    },
    reset () {
      this.service = {
        important: [],
        normal: []
      }
      this.params = {
        page: 1,
        page_size: 10
      }
      this.statusList = []
      this.alertService = []
      this.dataSource = []
    },
    fetch () {
      if (this.form.name) this.params.name = this.form.name
      this.spinning = true
      getServiceList(this.params)
        .then(res => {
          this.dataSource.push(...res.data.data)
          this.total = res.data.total
          res.data.data.forEach(service => {
            if (service.important) {
              this.service.important.push(service)
            } else {
              this.service.normal.push(service)
            }
          })
          if (this.total > this.dataSource.length) {
            this.params.page++
            this.fetch()
          }
        }).finally(() => { this.spinning = false })
    },
    search (value) {
      if (value) this.form.name = value
      else this.form.name = undefined
      this.reset()
      this.fetch()
    },
    alert ({ alert, id }) {
      const index = this.alertService.findIndex(item => item === id)
      if (alert) {
        if (index === -1) this.alertService.push(id)
      } else {
        if (index !== -1) this.alertService.splice(index, 1)
      }
    },
    refresh () {
      this.params.page = 1
      this.reset()
      this.fetch()
    },
    getStatus (item) {
      const id = item.service.id
      const status = item.status
      this.statusList.push({ id, status })
    }
  }
}
</script>

<style lang="less">
.service-wall {
  .service-search {
    width: 180px;
    .ant-input {
      background-color: #fff;
    }
  }
  .ant-collapse-borderless {
    background: transparent;

    & > .ant-collapse-item {
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

      .collapse-title {
        padding-left: 24px;
        position: relative;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 22px;
        line-height: 22px;

        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 20px;
          top: 1px;
          left: 10px;
          background: #096dd9;
          border-radius: 2px;
        }

        .collapse-icon {
          color: #5e98e3;
          font-size: 12px;
          vertical-align: middle;
          margin: 0 0 3px 8px;
        }
      }
    }
  }
}
</style>
