<template>
  <div :id="chartId"></div>
</template>

<script>
import moment from 'moment'
import { Chart } from '@antv/g2'
import { greenColor, orangeColor, redColor } from '@/utils/const'

const chart = {}

export default {
  name: 'SuccessRateChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 240
    }
  },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].axis('date', false)
    chart[this.chartId].axis('value', false)
    chart[this.chartId].legend(false)
    chart[this.chartId].tooltip({
      shared: true,
      showTitle: false,
      showMarkers: false,
      itemTpl: '<div style="line-height: 16px; font-size: 12px;margin-bottom: 8px;"><div>请求时间：</div><div>{date}</div><div>请求成功率：</div><div>{value}</div></div>'
    })

    chart[this.chartId]
      .interval(
        {
          background: {
            style: {
              radius: 0
            }
          }
        }
      )
      .size(8)
      .position('date*value')
      .color('value', (val) => {
        if (val >= 90) {
          return greenColor
        } else if (val >= 60) {
          return orangeColor
        } else {
          return redColor
        }
      })
      .tooltip('date*value', (date, value) => {
        return {
          date,
          value: value + '%'
        }
      })
    chart[this.chartId].interaction('active-region')

    this.render()
    this.$forceUpdate()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.formatterData(this.chartData))
        chart[this.chartId].changeVisible(true)
      }
    },
    formatterData (data) {
      return data.map(item => {
        return {
          date: moment(item.timestamp).format('YYYY-MM-DD HH:mm'),
          value: item.value
        }
      })
    }
  },
  watch: {
    chartData (v, ov) {
      this.render()
    }
  }
}
</script>
