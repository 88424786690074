<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'ServiceCountIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    component () {
      switch (this.icon) {
        case 'all':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M486.691591 738.679719c0 114.043383-92.448552 206.490911-206.490911 206.490911S73.708745 852.723102 73.708745 738.679719c0-114.042359 92.449575-206.490911 206.491934-206.490911L417.860946 532.188808l68.830645 0 0 68.830645L486.691591 738.679719 486.691591 738.679719zM532.579711 738.679719c0 114.043383 92.448552 206.490911 206.490911 206.490911s206.490911-92.447529 206.490911-206.490911c0-114.042359-92.448552-206.490911-206.490911-206.490911L601.410356 532.188808l-68.830645 0 0 68.830645L532.579711 738.679719 532.579711 738.679719zM486.691591 279.8108c0-114.043383-92.448552-206.490911-206.490911-206.490911S73.708745 165.767418 73.708745 279.8108s92.449575 206.490911 206.491934 206.490911L417.860946 486.301711l68.830645 0L486.691591 417.471067 486.691591 279.8108 486.691591 279.8108zM532.579711 279.8108 532.579711 279.8108c0-114.043383 92.448552-206.490911 206.490911-206.490911s206.490911 92.448552 206.490911 206.490911-92.448552 206.490911-206.490911 206.490911L601.410356 486.301711l-68.830645 0L532.579711 417.471067 532.579711 279.8108 532.579711 279.8108z" fill="currentColor" p-id="4431"></path>
              </svg>
            `
          }
        case 'important':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M768 64H256c-70.4 0-128 57.6-128 128v640c0 70.4 57.6 128 128 128l256-192 256 192c70.4 0 128-57.6 128-128V192c0-70.4-57.6-128-128-128zM640.036 480.032c55.99 168.015 20.165 209.851-127.972 96.009-148.136 113.843-183.963 72.006-127.974-96.009-143.044-104.008-125.681-157.618 47.991-160.013 54.737-170.346 104.981-171.037 159.967 0 173.663-3.089 190.859 56.005 47.988 160.013z" fill="currentColor" p-id="11778"></path>
              </svg>
            `
          }
        case 'normal':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M800.323 998.627 218.872 998.627c-52.602 0-135.1-66.15-135.1-135.02L83.772 314.524c0-57.977 59.385-135.005 122.8-135.005l73.54 0 0 130.797 458.126 0L738.238 179.52l64.34 0c63.43 0 132.845 58.667 132.845 135.47l0 552.744C935.423 947.508 863.758 998.627 800.323 998.627L800.323 998.627 800.323 998.627zM771.618 458.181c0-9.02-7.33-16.427-16.35-16.427L263.927 441.754c-9.022 0-16.347 7.408-16.347 16.427l0 32.755c0 9.035 7.325 16.345 16.347 16.345l491.341 0c9.02 0 16.35-7.307 16.35-16.345L771.618 458.181 771.618 458.181zM771.618 621.908c0-9.02-7.33-16.345-16.35-16.345L263.927 605.563c-9.022 0-16.347 7.325-16.347 16.345l0 32.755c0 9.12 7.325 16.425 16.347 16.425l491.341 0c9.02 0 16.35-7.305 16.35-16.425L771.618 621.908 771.618 621.908zM771.618 785.718c0-9.02-7.33-16.35-16.35-16.35L263.927 769.368c-9.022 0-16.347 7.33-16.347 16.35l0 32.755c0 9.035 7.325 16.345 16.347 16.345l491.341 0c9.02 0 16.35-7.31 16.35-16.345L771.618 785.718 771.618 785.718zM476.449 244.954 345.557 244.954 345.557 114.14l65.447 0c0-36.095 29.33-65.445 65.445-65.445l65.445 0c36.095 0 65.445 29.35 65.445 65.445l65.445 0 0 130.815L476.446 244.955 476.449 244.955zM509.206 103.872c-22.65 0-40.927 18.377-40.927 40.942 0 22.552 18.28 40.85 40.927 40.85 22.562 0 40.862-18.3 40.862-40.85C550.069 122.25 531.769 103.872 509.206 103.872L509.206 103.872 509.206 103.872z" fill="currentColor" p-id="14627"></path>
              </svg>
            `
          }
        case 'alert':
          return {
            template: `
            <svg viewBox="0 0 1028 1024" width="1em" height="1em">
              <path d="M1019.733333 294.4c8.533333 17.066667 4.266667 38.4-12.8 46.933333L896 405.333333l-34.133333-59.733333 106.666666-64c8.533333-4.266667 17.066667-4.266667 25.6-4.266667 12.8 4.266667 21.333333 8.533333 25.6 17.066667zM4.266667 294.4c4.266667-8.533333 12.8-12.8 21.333333-17.066667 8.533333-4.266667 17.066667 0 25.6 4.266667l106.666667 64-34.133334 59.733333L17.066667 341.333333C0 332.8-4.266667 311.466667 4.266667 294.4z m213.333333-213.333333c8.533333-4.266667 17.066667-4.266667 25.6-4.266667 8.533333 4.266667 17.066667 8.533333 21.333333 17.066667l64 106.666666-59.733333 34.133334L204.8 128c-8.533333-17.066667-4.266667-38.4 12.8-46.933333zM512 0c21.333333 0 38.4 17.066667 38.4 34.133333v123.733334h-72.533333V34.133333c-4.266667-17.066667 12.8-34.133333 34.133333-34.133333z m290.133333 81.066667c17.066667 8.533333 21.333333 29.866667 12.8 46.933333l-64 106.666667-59.733333-34.133334 64-106.666666c8.533333-8.533333 12.8-12.8 21.333333-17.066667 8.533333 0 21.333333 0 25.6 4.266667z m-264.533333 315.733333L362.666667 682.666667h140.8l-34.133334 213.333333 174.933334-285.866667h-140.8l34.133333-213.333333zM230.4 947.2H157.866667v-341.333333C157.866667 413.866667 315.733333 256 512 256c196.266667 0 354.133333 157.866667 354.133333 349.866667v341.333333H230.4z" fill="currentColor" p-id="24350"></path>
              <path d="M38.4 947.2h951.466667c21.333333 0 38.4 17.066667 38.4 38.4s-17.066667 38.4-38.4 38.4H38.4c-21.333333 0-38.4-17.066667-38.4-38.4s17.066667-38.4 38.4-38.4" fill="currentColor" p-id="24351"></path>
            </svg>
            `
          }
        default:
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M486.691591 738.679719c0 114.043383-92.448552 206.490911-206.490911 206.490911S73.708745 852.723102 73.708745 738.679719c0-114.042359 92.449575-206.490911 206.491934-206.490911L417.860946 532.188808l68.830645 0 0 68.830645L486.691591 738.679719 486.691591 738.679719zM532.579711 738.679719c0 114.043383 92.448552 206.490911 206.490911 206.490911s206.490911-92.447529 206.490911-206.490911c0-114.042359-92.448552-206.490911-206.490911-206.490911L601.410356 532.188808l-68.830645 0 0 68.830645L532.579711 738.679719 532.579711 738.679719zM486.691591 279.8108c0-114.043383-92.448552-206.490911-206.490911-206.490911S73.708745 165.767418 73.708745 279.8108s92.449575 206.490911 206.491934 206.490911L417.860946 486.301711l68.830645 0L486.691591 417.471067 486.691591 279.8108 486.691591 279.8108zM532.579711 279.8108 532.579711 279.8108c0-114.043383 92.448552-206.490911 206.490911-206.490911s206.490911 92.448552 206.490911 206.490911-92.448552 206.490911-206.490911 206.490911L601.410356 486.301711l-68.830645 0L532.579711 417.471067 532.579711 279.8108 532.579711 279.8108z" fill="currentColor" p-id="4431"></path>
              </svg>
            `
          }
      }
    }
  }
}
</script>
