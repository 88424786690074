<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'MessageIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    component () {
      switch (this.icon) {
        case 'urgent':
          return {
            template: `
            <svg viewBox="0 0 1024 1024" width="1em" height="1em">
              <path d="M572.074667 337.134933a57.275733 57.275733 0 1 0-114.176-6.007466h-0.170667l12.936533 272.896v0.443733a44.544 44.544 0 1 0 89.019734-1.194667l12.424533-266.1376z m-196.949334-191.214933c76.151467-130.048 199.816533-129.774933 275.797334 0l340.3776 581.085867c76.117333 130.048 15.7696 235.4176-135.236267 235.4176H169.984c-150.8352 0-211.217067-105.6768-135.202133-235.4176L375.125333 145.92z m140.049067 687.581867a57.275733 57.275733 0 1 0 0-114.517334 57.275733 57.275733 0 0 0 0 114.517334z" fill="currentColor" p-id="4442"></path>
            </svg>
            `
          }
        case 'important':
          return {
            template: `
            <svg viewBox="0 0 1024 1024" width="1em" height="1em">
              <path d="M510.865 63.868c-247.307 0-447.572 200.713-447.572 448.02s200.265 448.02 447.572 448.02c247.755 0 448.468-200.713 448.468-448.02S758.62 63.868 510.865 63.868z m0.448 806.436c-198.025 0-358.416-160.391-358.416-358.416s160.391-358.416 358.416-358.416 358.416 160.391 358.416 358.416-160.391 358.416-358.416 358.416z m22.401-582.426h-67.203V556.69l235.21 141.126 33.602-55.106-201.609-119.621V287.878z" p-id="10058"></path>
            </svg>
            `
          }
        case 'attention':
          return {
            template: `
            <svg viewBox="0 0 1489 1024" width="1em" height="1em">
              <path d="M744.683269 95.908689a709.77624 709.77624 0 0 1 625.068518 366.75651 100.532241 100.532241 0 0 1 0 98.670533A709.77624 709.77624 0 0 1 744.683269 928.092242a710.241668 710.241668 0 0 1-625.533946-366.75651 100.532241 100.532241 0 0 1 0-98.670533A710.241668 710.241668 0 0 1 744.683269 95.908689M744.683269 0.030718A798.207379 798.207379 0 0 0 8.377687 473.835448a93.085409 93.085409 0 0 0 0 76.795462A798.672806 798.672806 0 0 0 744.683269 1023.970213a799.60366 799.60366 0 0 0 736.305582-473.339303 95.877971 95.877971 0 0 0 0-76.795462A798.672806 798.672806 0 0 0 744.683269 0.030718z" p-id="18688"></path><path d="M744.683269 274.167246A238.764073 238.764073 0 1 1 506.384623 512.000465 238.764073 238.764073 0 0 1 744.683269 274.167246M744.683269 186.201535a325.79893 325.79893 0 1 0 325.79893 325.79893 325.79893 325.79893 0 0 0-325.79893-325.79893z" p-id="18689"></path>
            </svg>
            `
          }
        case 'healthy':
          return {
            template: `
            <svg viewBox="0 0 1024 1024" width="1em" height="1em">
              <path d="M512 1019.2C232 1019.2 4.8 792 4.8 512S232 4.8 512 4.8 1019.2 232 1019.2 512 792 1019.2 512 1019.2z m0-918.4C284.8 100.8 100.8 284.8 100.8 512S284.8 923.2 512 923.2 923.2 739.2 923.2 512 739.2 100.8 512 100.8z" fill="currentColor" p-id="19691"></path>
              <path d="M761.6 336c-19.2-19.2-54.4-19.2-73.6 0L444.8 577.6l-107.2-107.2c-20.8-20.8-52.8-20.8-73.6 0s-20.8 52.8 0 73.6l144 144c9.6 9.6 24 16 36.8 16 6.4 0 12.8-1.6 19.2-3.2 6.4-3.2 14.4-6.4 19.2-12.8l278.4-278.4c20.8-20.8 20.8-54.4 0-73.6z" fill="currentColor" p-id="19692"></path>
            </svg>
            `
          }
        default:
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M486.691591 738.679719c0 114.043383-92.448552 206.490911-206.490911 206.490911S73.708745 852.723102 73.708745 738.679719c0-114.042359 92.449575-206.490911 206.491934-206.490911L417.860946 532.188808l68.830645 0 0 68.830645L486.691591 738.679719 486.691591 738.679719zM532.579711 738.679719c0 114.043383 92.448552 206.490911 206.490911 206.490911s206.490911-92.447529 206.490911-206.490911c0-114.042359-92.448552-206.490911-206.490911-206.490911L601.410356 532.188808l-68.830645 0 0 68.830645L532.579711 738.679719 532.579711 738.679719zM486.691591 279.8108c0-114.043383-92.448552-206.490911-206.490911-206.490911S73.708745 165.767418 73.708745 279.8108s92.449575 206.490911 206.491934 206.490911L417.860946 486.301711l68.830645 0L486.691591 417.471067 486.691591 279.8108 486.691591 279.8108zM532.579711 279.8108 532.579711 279.8108c0-114.043383 92.448552-206.490911 206.490911-206.490911s206.490911 92.448552 206.490911 206.490911-92.448552 206.490911-206.490911 206.490911L601.410356 486.301711l-68.830645 0L532.579711 417.471067 532.579711 279.8108 532.579711 279.8108z" fill="currentColor" p-id="4431"></path>
              </svg>
            `
          }
      }
    }
  }
}
</script>
