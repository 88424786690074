<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '业务编辑' : '业务创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="业务名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="监控识别 ID" prop="key">
        <a-input
          v-model="form.key"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="业务描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="项目经理" prop="projectManager">
        <a-input v-model="form.projectManager" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="承建经理" prop="constructionManager">
        <a-input v-model="form.constructionManager" :max-length="128"></a-input>
      </a-form-model-item>

      <a-form-model-item label="关联应用" prop="applications">
        <multi-source-select
          v-model="form.applications"
          source-type="application"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item label="关联站点" prop="sites">
        <multi-source-select
          v-model="form.sites"
          :show-left-tool="true"
          source-type="site"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item label="关联域名" prop="domains">
        <multi-source-select
          v-model="form.domains"
          :show-left-tool="true"
          source-type="domain"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item label="关联 SSL 证书" prop="sslCertificates">
        <multi-source-select
          v-model="form.sslCertificates"
          :show-left-tool="true"
          source-type="ssl_certificate"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item label="重点业务" prop="important">
        <a-switch v-model="form.important"></a-switch>
      </a-form-model-item>

      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getService,
  getServiceApplicationList,
  getServiceSiteList,
  updateService,
  createService
} from '@/api/service'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        key: '',
        description: '',
        projectManager: '',
        constructionManager: '',
        applications: undefined,
        sites: undefined,
        domains: undefined,
        sslCertificates: undefined,
        important: false
      },
      rules: {
        name: [{
          message: '请输入业务名称',
          required: true,
          trigger: 'blur'
        }],
        key: [{
          message: '请输入业务 Key',
          required: true,
          trigger: 'blur'
        }]
      },
      loading: false
    }
  },
  methods: {
    async show (id) {
      if (id) {
        this.isUpdate = true
        await getService(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              key: data.key,
              description: data.description,
              projectManager: data.project_manager,
              constructionManager: data.construction_manager,
              domains: data.domains,
              sslCertificates: data.ssl_certificates,
              important: data.important
            }
          })
        await getServiceApplicationList(id).then(res => {
          this.form.applications = res.data.data
        })
        await getServiceSiteList(id).then(res => {
          this.form.sites = res.data.data
        })
        this.visible = true
      } else {
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        key: '',
        description: '',
        projectManager: '',
        constructionManager: '',
        applications: undefined,
        sites: undefined,
        domains: undefined,
        sslCertificates: undefined,
        important: false
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name,
            description: this.form.description,
            project_manager: this.form.projectManager,
            construction_manager: this.form.constructionManager,
            important: this.form.important
          }
          if (this.form.applications) {
            params.application_ids = this.form.applications.map(
              application => application.id
            )
          }
          if (this.form.sites) {
            params.site_ids = this.form.sites.map(site => site.id)
          }
          if (this.form.domains) {
            params.domain_ids = this.form.domains.map(domain => domain.id)
          }
          if (this.form.sslCertificates) {
            params.ssl_certificate_ids = this.form.sslCertificates.map(sslCertificate => sslCertificate.id)
          }
          if (this.isUpdate) {
            updateService(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.key = this.form.key
            createService(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
