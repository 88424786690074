<template>
  <div :class="cardClass">
    <a-space :size="39">
      <service-count-icon class="icon" :icon="icon"></service-count-icon>
      <a-statistic
        :title="title"
        :value="count"
        :valueStyle="{
          color: '#fff',
          fontSize: '32px',
          fontWeight: 500,
          lineHeight: '45px'
        }"
        style="color: #fff; margin-right: 24px;"></a-statistic>
    </a-space>
  </div>
</template>

<script>
import ServiceCountIcon from './ServiceCountIcon'

export default {
  name: 'ServiceCountCard',
  components: {
    ServiceCountIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      default: 0
    },
    icon: {
      type: String
    }
  },
  computed: {
    cardClass () {
      switch (this.icon) {
        case 'all':
          return 'container all-bg'
        case 'important':
          return 'container important-bg'
        case 'normal':
          return 'container normal-bg'
        case 'alert':
          return 'container alert-bg'
        default:
          return 'container'
      }
    }
  }
}
</script>

<style scoped>
.container {
  height: 140px;
  border-radius: 8px;
  text-align: center;
  padding-top: 34px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
}
.all-bg {
  background: url('../../../../assets/images/service-wall/total-all.png') 100% no-repeat;
  background-size: cover;

}
.important-bg {
  background: url('../../../../assets/images/service-wall/total-important.png') 100% no-repeat;
  background-size: cover;

}
.normal-bg {
  background: url('../../../../assets/images/service-wall/total-normal.png') 100% no-repeat;
  background-size: cover;

}
.alert-bg {
  background: url('../../../../assets/images/service-wall/total-alert.png') 100% no-repeat;
  background-size: cover;
}
:deep(.ant-statistic-title)  {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
}
.container .icon {
  font-size: 56px;
}
</style>
